import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';
import { BusinessUnitUI } from 'app/shared/models/business-unit.model';
import {
  ClientService,
  HelperService,
  UserService
} from 'app/shared/services';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap
} from 'rxjs/operators';

@Component ( {
  selector: 'reporter-business-unit-search',
  templateUrl: './business-unit-search.component.html',
  styleUrls: [ './business-unit-search.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )
export class BusinessUnitSearchComponent implements OnChanges {
  @Output () onUpdate = new EventEmitter<BusinessUnitUI> ();
  @Output () onOpenTreeView = new EventEmitter ();
  @Input () subUnitsChecked = true;
  @Input () selectedBu;
  @Input () required = false;
  @Input () label = 'Reporting Location';
  @Input () showSubUnitsChecked = true;
  @Input () clientNumberOverride: string;

  selectedItem;
  results: [];
  activeDate: string;

  constructor(
    private clientService: ClientService,
    private userService: UserService,
    private helperService: HelperService ) {
    this.activeDate = new Date ().toISOString ()
      .split ( 'T' )[ 0 ];
  }

  ngOnChanges(): void {
    this.selectedItem = this.helperService.transformToBuSearchTypeahead ( this.selectedBu );
  }

  search = ( text$: Observable<string> ) => text$.pipe (
    debounceTime ( 200 ),
    distinctUntilChanged (),
    switchMap ( ( term ) => {
      if ( term.trim ().length >= 3 ) {
        return this.clientService.searchReportingLocation ( this.clientNumber, term.trim (), this.activeDate, 1, 10 )
          .pipe (
            map ( ( result ) => this.helperService.transformToBuSearchTypeahead ( result.records ) ) );
      } else {
        return of ( [] );
      }
    } ),
  )

  formatter = ( x: { displayName: string } ) => x.displayName;

  selectItem( event: NgbTypeaheadSelectItemEvent ): void {
    this.selectedItem = event.item;
    this.emitLocalData ();
  }

  clear(): void {
    this.selectedItem = undefined;
    this.emitLocalData ();
  }

  emitLocalData(): void {
    this.onUpdate.emit ( { selectedItem: this.selectedItem, subUnitsChecked: this.subUnitsChecked } );
  }

  get clientNumber() {
    return this.clientNumberOverride ?? this.userService.currentClient?.clientNumber;
  }

  openTreeView() {
    this.onOpenTreeView.emit ();
  }
}
